import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Container, Card, CardContent, CardMedia } from "@material-ui/core";
import { useTrail, animated } from 'react-spring';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: "black",
    borderRadius: 5,
    color: "white",
  },
  title: {
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: theme.spacing(2),
    color: "gold",
  },
  content: {
    lineHeight: 1.6,
    marginBottom: theme.spacing(2),
  },
  productCard: {
    backgroundColor: "#333",
    color: "white",
    width: 250,
    display: "inline-block",
    margin: theme.spacing(1),
    verticalAlign: "top",
    cursor: "pointer",
    position: "relative",
    overflow: "hidden",
    "&:hover $media": {
      transform: "scale(1.1)",
    },
  },
  media: {
    height: 200,
    transition: "transform 0.3s ease",
  },
  sliderContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: 20,
  },
  productTitle: {
    fontWeight: "bold",
    color: "gold",
    fontSize: 16,
  },
  productPrice: {
    color: "white",
  },
}));

function ProductCatalogue() {
  const classes = useStyles();

  const products = [
    {
      title: "ANTMINER S19 SERIES",
      image: "https://cdn.globalso.com/jsbit/ANTMINER-S19-SERIES.png",
      price: "Consult a Quote",
    },
    {
      title: "BITCOIN MINER S21",
      image: "https://cdn.globalso.com/jsbit/S21-400x400.jpg",
      price: "Consult a Quote",
    },
    {
      title: "KAS MINER KS3",
      image: "https://cdn.globalso.com/jsbit/2024.1KS3.jpg",
      price: "Consult a Quote",
    },
    {
      title: "ANTMINER S19 PRO",
      image: "https://cdn.globalso.com/jsbit/Antminer-S19-Pro.jpg",
      price: "Consult a Quote",
    },
    {
      title: "ANTMINER S19J PRO+",
      image: "https://cdn.globalso.com/jsbit/Antminer-S19J-Pro+-0012.jpg",
      price: "Consult a Quote",
    },
    {
      title: "Bitcoin Miner S19 XP Hyd.",
      image: "https://file.bitmain.com/shop-bitmain/proshow/2024/04/11/17/a325f0c7-820b-4b28-a33d-4841a1c73eef.jpg",
      price: "Consult a Quote",
    },
  ];

  const trail = useTrail(products.length, {
    from: { opacity: 0, transform: 'translate3d(0,-40px,0)' },
    to: { opacity: 1, transform: 'translate3d(0,0px,0)' },
  });

  return (
    <Container className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        Minors Collection
      </Typography>

      <Typography variant="body1" className={classes.content}>
        Enhance your experience with our selection of Minors, carefully curated to complement our main product offerings. From stylish accessories to functional add-ons, these items are designed to elevate your experience and provide added convenience.
      </Typography>

      <Typography variant="h5" className={classes.title}>
        Accessories
      </Typography>
      <Typography variant="body1" className={classes.content}>
        Discover a range of accessories that are perfect for enhancing the functionality and style of your main products. Whether you're looking for protective cases, stylish straps, or convenient carrying solutions, our Accessories collection has you covered.
      </Typography>

      <Typography variant="h5" className={classes.title}>
        Add-ons
      </Typography>
      <Typography variant="body1" className={classes.content}>
        Our Add-ons collection features a variety of products that can enhance the performance or capabilities of your main products. From additional attachments to upgrade kits, these add-ons are designed to unlock new possibilities and extend the life of your products.
      </Typography>

      <Typography variant="h5" className={classes.title}>
        Supplementary Items
      </Typography>
      <Typography variant="body1" className={classes.content}>
        Complete your set with our selection of Supplementary Items, designed to complement your main products and enhance their usability. From cleaning kits to maintenance tools, these items are essential for keeping your products in top condition.
      </Typography>

      <Typography variant="body1" className={classes.content}>
        Explore our Minors collection and discover how these supplementary items can enhance your overall experience with our products.
      </Typography>

      <Typography variant="h5" className={classes.title}>
        Featured Products
      </Typography>
      <div className={classes.sliderContainer}>
        {trail.map((style, index) => (
          <animated.div key={index} style={style}>
            <Card className={classes.productCard}>
              <CardMedia
                className={classes.media}
                component="img"
                image={products[index].image}
                alt={products[index].title}
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="h2" className={classes.productTitle}>
                  {products[index].title}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" className={classes.productPrice}>
                  {products[index].price}
                </Typography>
              </CardContent>
            </Card>
          </animated.div>
        ))}
      </div>
    </Container>
  );
}

export default ProductCatalogue;
