import React from "react";
import { Container, Typography, Link, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InstagramIcon from "@material-ui/icons/Instagram";
import logo from '../../src/images/logo.png'
import logo1 from "../../src/images/logo1.png"


const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    backgroundColor: "black",
    color: "#fff",
    textAlign: "center",
  },
  companyLogo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
  instagramLink: {
    color: "#FBB400",
    marginLeft: theme.spacing(1),
  },
}));

function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container>
        <div className={classes.companyLogo}>
          {/* Replace 'logo.png' with the actual path to your company logo */}
          <img src={logo} alt="Mine United Logo" style={{ height: 50 }}/>
          <img src={logo1} alt="Mine United Logo" style={{ height: 50 }}/>
          <Typography variant="h6" style={{ marginLeft: 10 }}>
            {/* Mine United (Text with logo like company name ) */}
          </Typography>
        </div>
        <IconButton style={{ color: "#C13584" }}>
          <InstagramIcon />
          <Link href="https://www.instagram.com/mineunitedofficial?igsh=ZjhtZmplbTdocDF1&utm_source=qr" target="_blank" rel="noopener" className={classes.instagramLink} style={{ fontSize: 16}}>
          Instagram
        </Link>
        </IconButton>

        <Typography variant="body2" style={{ marginTop: 20 }}>
          © {new Date().getFullYear()} Mine United Official. All Rights Reserved.
        </Typography>
      </Container>
    </footer>
  );
}

export default Footer;
