import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    // backgroundColor: "#222",
    borderRadius: 10,
    color: "white",
    marginBottom: theme.spacing(4),
  },
  title: {
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: theme.spacing(2),
    color: "gold",
    fontSize: "2rem",
  },
  subtitle: {
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: theme.spacing(2),
    color: "gold",
    fontSize: "1.5rem",
  },
  content: {
    lineHeight: 1.6,
    marginBottom: theme.spacing(1),
  },
  bulletPoint: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(1),
  },
  bullet: {
    marginRight: theme.spacing(1),
    fontSize: "1.2em",
    color: "gold",
  },
}));

function About() {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        About MineUnited
      </Typography>
      <Typography variant="body1" className={classes.content}>
        At <strong>MineUnited</strong>, we are dedicated to providing a seamless and rewarding trading experience for all our users. Our platform is designed to cater to both novice traders looking to enter the world of trading and experienced traders seeking advanced tools and features.
      </Typography>
      <Typography variant="h5" className={classes.subtitle}>
        Our Mission
      </Typography>
      <Typography variant="body1" className={classes.content}>
        <strong>Our mission</strong> is to democratize trading by providing access to a wide range of financial markets and instruments. We believe that everyone should have the opportunity to participate in the global economy and benefit from the potential of financial markets.
      </Typography>
      <Typography variant="h5" className={classes.subtitle}>
        What Sets Us Apart
      </Typography>
      <Typography variant="body1" className={classes.content}>
        <strong>What sets us apart is our:</strong>
      </Typography>
      <div className={classes.bulletPoint}>
        <Typography variant="body1" className={classes.bullet}>•</Typography>
        <Typography variant="body1" className={classes.content}>
          User-friendly interface
        </Typography>
      </div>
      <div className={classes.bulletPoint}>
        <Typography variant="body1" className={classes.bullet}>•</Typography>
        <Typography variant="body1" className={classes.content}>
          Advanced trading tools
        </Typography>
      </div>
      <div className={classes.bulletPoint}>
        <Typography variant="body1" className={classes.bullet}>•</Typography>
        <Typography variant="body1" className={classes.content}>
          Commitment to security
        </Typography>
      </div>
      <div className={classes.bulletPoint}>
        <Typography variant="body1" className={classes.bullet}>•</Typography>
        <Typography variant="body1" className={classes.content}>
          Dedicated customer support
        </Typography>
      </div>
      <Typography variant="body1" className={classes.content}>
        Join us at MineUnited and experience the future of trading!
      </Typography>
    </Container>
  );
}

export default About;
