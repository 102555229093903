import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Container, TextField, Button, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: "black",
    borderRadius: 5,
    color: "white",
  },
  title: {
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: theme.spacing(2),
    color: "gold", // Gold color for the main title
  },
  content: {
    lineHeight: 1.6,
    marginBottom: theme.spacing(1),
  },
  form: {
    marginTop: theme.spacing(2),
  },
  formField: {
    marginBottom: theme.spacing(2),
    "& .MuiInputBase-input": {
      color: "white", // White color for form field text
    },
    "& .MuiInputLabel-root": {
      color: "gold", // Gold color for form field labels
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "gold", // Gold color for form field outlines
    },
  },
  submitButton: {
    backgroundColor: "gold",
    color: "black",
    "&:hover": {
      backgroundColor: "#FFD700",
    },
  },
}));

function Contact() {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Your message has been sent to the company!");
    setFormData({ name: "", email: "", message: "" });
  };

  return (
    <Container className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        Contact Us
      </Typography>
      <Typography variant="body1" className={classes.content}>
        At <strong>MineUnited</strong>, we value your feedback and inquiries. Whether you have a question about our platform, need assistance with your account, or want to learn more about our services, we're here to help.
      </Typography>
      <Typography variant="body1" className={classes.content}>
        You can reach out to us using the form below, and one of our dedicated customer support representatives will get back to you as soon as possible.
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Name"
              name="name"
              variant="outlined"
              fullWidth
              className={classes.formField}
              value={formData.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              name="email"
              variant="outlined"
              fullWidth
              className={classes.formField}
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Message"
              name="message"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              className={classes.formField}
              value={formData.message}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              className={classes.submitButton}
            >
              Send Message
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default Contact;
